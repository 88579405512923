<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{xs:8}" :wrapper-col="{xs:16}">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item>
              <div class="select-btns">
                <div class="btn btn-left" :class="handle == true ? 'active' : ''" @click="getData">员工绩效</div>
                <div class="btn btn-right" :class="handle == false ? 'active' : ''" @click="getStoreData">门店绩效</div>
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="门店">
              <a-select placeholder="请选择" v-model="searchData.storeId" allowClear>
                <a-select-option :value="item.id" v-for="(item, index) in shopList" :key="index">{{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="创建日期">
              <DateRange :startTime.sync="searchData.startDate"
                         :endTime.sync="searchData.endDate"></DateRange>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="" :label-col="{xs:0}" :wrapper-col="{xs:24}">
              <a-input v-model="searchData.search" placeholder="输入门店名称/员工姓名快捷检索" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="4" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button type="primary" @click="toHandler('add')">新增</a-button>
        <a-button type="primary" @click="toHandler('edit')">修改</a-button>
        <a-button type="primary" @click="toHandler('del')">删除</a-button>
      </div>
      <!-- 数据表格 -->
      <a-table :loading="tableLoading" :row-selection="rowSelection" :rowKey="record => record.id"
               :data-source="tableData" bordered :pagination="page"
               @change="changeTable" :customRow="changeTableRow">
        <a-table-column title="序号" data-index="" align="center">
          <template slot-scope="text, row, index">{{ index + 1 }}</template>
        </a-table-column>
        <a-table-column title="门店名称" data-index="storeName"></a-table-column>
        <a-table-column title="员工姓名" v-if="handle == true" data-index="managerName"></a-table-column>
        <a-table-column title="目标（万元）" data-index="targetAmount" align="center">
          <template slot-scope="text"> {{ text | formatMoney }}</template>
        </a-table-column>
        <a-table-column title="达成（万元）" data-index="reachAmount" align="center">
          <template slot-scope="text"> {{ text | formatMoney }}</template>
        </a-table-column>
        <a-table-column title="达成率" data-index="rate" align="center"></a-table-column>
        <a-table-column title="时间" data-index="targetDate" align="center" :width="150"></a-table-column>
      </a-table>
      <!-- 编辑表单 -->
      <DealerAchievements-edit-modal ref="DealerAchievementsEditModal"
                                     @reload="getData"></DealerAchievements-edit-modal>
    </div>
  </a-card>
</template>

<script>
import DealerAchievementsEditModal from './components/DealerAchievementsEditModal.vue'
import {delDealerAchievements, listDealerAchievements, selectByIdDealerAchievements} from './api/DealerAchievementsApi'
import {checkPermission} from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0
}
export default {
  name: 'dealerAchievements',
  components: {
    DealerAchievementsEditModal
  },
  data() {
    return {
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: {y: 600},
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      handle: true,
      shopList: [],
      isAdd: checkPermission('delaer:achievements:add'),
      isEdit: checkPermission('delaer:achievements:edit'),
      isDelete: checkPermission('delaer:achievements:delete'),
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        }
      }
    }
  },
  methods: {
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.handle = true
      listDealerAchievements({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        type: 1,
        ...this.searchData,
      }).then(res => {
        if (res.code === 200) {
          const {total, records} = res.body
          this.page.total = total
          this.tableData = records
        }
      }).finally(() => this.tableLoading = false)
      this.axios.get(`/api/base/store/dealerStore/storeListForDealer`).then((res) => {
        if (res.code == 200) {
          this.shopList = res.body
        }
      })
    },
    getStoreData() {
      this.tableLoading = true
      this.handle = false
      listDealerAchievements({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        type: 2,
        ...this.searchData,
      }).then(res => {
        if (res.code === 200) {
          const {total, records} = res.body
          this.page.total = total
          this.tableData = records
        }
      }).finally(() => this.tableLoading = false)
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page = Object.assign({}, this.page)
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page = Object.assign({}, this.page)
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: e => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          }
        }
      }
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.DealerAchievementsEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录'
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.DealerAchievementsEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delDealerAchievements(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({message: res.message})
                _this.getData()
              } else {
                _this.$notification.error({message: res.message})
              }
            },
            onCancel() {
            }
          })
          break
      }
    },
  },
  created() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}

.select-btns {
  display: flex;
  margin-top: 5px;
}

.btn {
  width: 100px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #e8e8e8;
  text-align: center;
  cursor: pointer;
}

.btn-left {
  border-radius: 5px 0 0 5px;
}

.btn-right {
  border-radius: 0 5px 5px 0;
}

.active {
  color: #1ABC9C;
  border: 1px solid #1ABC9C;
}
</style>
